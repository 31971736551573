@import '../config';
@import '../functions';
@import '../mixins';
@import './action-menu-config';

.ace-c-action-menu {
    padding: map-get($action-menu-config, action-menu-spacing) 0;
    max-width: map-get($action-menu-config, action-menu-content-max-width);
}

.ace-c-action-menu__content {
    overflow: hidden;
    background: map-get($action-menu-config, action-menu-background);
    border-radius: map-get($action-menu-config, action-menu-border-radius);
    box-shadow: map-get($action-menu-config, action-menu-shadow);
}
