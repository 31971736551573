// TODO: check later if anything can be pulled out into util classes
.ace-c-contract-partner-item {
    margin-bottom: 16px;

    &:last-child {
        margin-bottom: 0;
    }

    &--highlighted {
        background-color: #fcf2e1;
    }
}
