@import '../config';
@import '../functions';
@import '../mixins';
@import './panel-header-config';

.ace-c-panel-header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: map-get($panel-header-config, panel-header-padding) 0;
    background: map-get($panel-header-config, panel-header-background);
}
