@import '../config';
@import '../functions';
@import '../mixins';
@import './drop-down-config';

.ace-c-drop-down {
    padding: map-get($drop-down-config, drop-down-spacing) 0;
}

.ace-c-drop-down__content {
    overflow: hidden;
    background: map-get($drop-down-config, drop-down-background);
    border-radius: map-get($drop-down-config, drop-down-border-radius);
    box-shadow: map-get($drop-down-config, drop-down-shadow);
}
