.ace-c-service-description-panel {
    &--has-service {
        background-color: #f1b055;
        color: #fff;

        & * {
            color: #fff;
        }
    }
}

// todo move all of this to ButtonSecondary and add "contrast"
//  class https://www.figma.com/file/0OdPuwdmckyJuMPz5XJ482/CR-ACE-Notrufsystem?node-id=16%3A342

.ace-c-service-description-panel__button {
    &--has-service {
        background-color: #f1b055;
        color: #fff;
        border-color: #fff;

        &:hover:not(#{&}--is-disabled):not(:focus) {
            background-color: #f3bc6e;
            border-color: #fff;
            color: #fff;
        }

        &:active:not(#{&}--is-disabled) {
            background-color: #f3bc6e;
            border-color: #fff;
            color: #fff;
        }
    }
}
